<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" v-loading="loading">
                <el-form-item label="小区名称" prop="towerNum">
                    <el-input v-model="ruleForm.villageName" placeholder="请输入小区名称" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="楼幢号" prop="type">
                    <el-input v-model="ruleForm.towerNum" placeholder="请输入楼幢号" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="单元名称" prop="unitName">
                    <el-input v-model="ruleForm.unitName" placeholder="请输入单元名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="submitForm('ruleForm')">修改</el-button>
                    <el-button @click="back()" size="small">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";
    export default {
        name: "upUnit",
        components:{
            commonTitle
        },
        data() {
            return {
                ruleForm: {
                    towerNum: '',  //楼幢号
                    villageId: '',   //小区Id
                    unitName: '',//单元名称
                    villageName: '',//小区名称
                    towerId: '',//楼幢Id
                    unitId: ''//单元Id
                },
                rules: {
                    unitName: [
                        {required: true, message: '请输入单元名称', trigger: 'change'},
                    ]
                },
                towerTypeList: [],  //楼幢类型
                dtList: [],        //电梯类型
                payList: [],          //缴费标准
                url: this.$Config.base_server,
                loading:false
            };
        }, mounted() {
            this.common();
        }, methods: {
            common() {

            }
            , selectOne(event, item) {
            }
            , submitForm(formName) {
                var _this = this;
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        _this.loading=true;
                        $.ajax({
                            url: _this.url + "/api-public/opeunit/update?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    _this.loading=false;
                                    //修改成功
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'success',
                                        duration: 1000,
                                        onClose: function () {
                                            window.history.back(-1);
                                        }
                                    });

                                } else {
                                    _this.loading=false;
                                    //修改失败
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'warning'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, back() {
                //返回按钮
                this.$router.go(-1);
            }
        }, created() {
            //初始化数据
            var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
            //获取小区信息
            var tolist1 = JSON.parse(localStorage.getItem('villageData'));//取当前值
            //获取楼幢信息
            var tolist2 = JSON.parse(localStorage.getItem('towerData'));//取当前值
            //获取单元信息
            var tolist3 = JSON.parse(localStorage.getItem('unitData'));//取当前值
            _this.ruleForm.villageName = tolist1.villageName;
            _this.ruleForm.villageId = tolist1.villageId;
            _this.ruleForm.towerNum = tolist2.towerNum;
            _this.ruleForm.towerId = tolist2.towerId;
            _this.ruleForm.unitName = tolist3.unitName;
            _this.ruleForm.unitId = tolist3.unitId;
        }
    }
</script>

<style scoped>

    .el-form{
        width: 600px;
        margin: 0 auto;
    }
</style>